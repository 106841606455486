import React, { useState } from 'react'
import axios from 'axios'
import './AddAUser.css'
import { serverUrl } from '../../AppUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function DeleteUser({e,loginDetail,users,ele}) {
    console.log(e,"e",loginDetail,"loginDetail",ele,"ele")
    const notify =(text)=> toast(text)
    const deleteAuser = () =>{
        axios.post(`${serverUrl.u}/api/usr/deleteUser`,{
            houseId:e.houseId,
            propertyId:loginDetail.property,
            adminId:loginDetail.userName,
            userId:ele.email,
            newowner:false
        }).then(function (res) {
             console.log(res);
             if(res.status===200){
              notify(res.data.msg)
              users(e)
             }
        }).catch(function (err) {
            console.log(err);
        })
    }
   
  return (
    <>
   
    
      <button className='btn btn-danger' onClick={()=>deleteAuser()}>Delete User</button>
      <ToastContainer/>
    
    </>
  )
}
