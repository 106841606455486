import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { userList } from '../Axios/AxiosFunction';
import { useSelector } from "react-redux"
import AddAUser from '../Axios/AddAUser';
import DeleteUser from '../Axios/DeleteUser';
import UpdateUser from '../Axios/UpdateUser';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

export default function RoomExpend({e}) {
  let loginDetail = useSelector((state)=>state.loginDetail)
    const [expanded, setExpanded] = useState(false);
    const [user,setUser] = useState([])
    console.log(loginDetail,"log");
    const handleExpandClick = () => {
        setExpanded(!expanded);
      };
    
    const users = async(e) =>{
      console.log(e,"ee");
        let data = await userList(e,loginDetail);
        console.log(data,"data");
        if(data.status===200){
            setUser(data.data.users.users)
        }
    }

  return (
    <div style={{width:"100%"}}>
           <Card sx={{margin:"15px 0px",width:"100%" }}>
           <div className='roomExpend'>
            Room Number :- {e.roomsName}
           </div>
 
 <CardActions disableSpacing sx={{height:"25px",padding:"0 !important"}}>
  

   <ExpandMore
     sx={{height:"25px",padding:"0 !important"}}
     className="expandmore"
     expand={expanded}
     onClick={handleExpandClick}
     aria-expanded={expanded}
     aria-label="show more"
   >
      
     <ExpandMoreIcon onClick={()=>users(e)}/>
     {/* read more */}
   
   </ExpandMore>
 </CardActions>
 <Collapse in={expanded} timeout="auto" unmountOnExit>
   <CardContent sx={{padding:"0px !important"}}>
         <AddAUser e={e} loginDetail={loginDetail} users={users}/>
         { user.length!==0?
          <table className='table table-sm table-bordered'>
            <thead>
              <tr>
                <th>Name</th>
                <th>User ID</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
            {
      user.map((ele,i)=>{
         console.log(ele,"list");
         return(
            <tr key={i}>
              <td>{ele.name}</td>
              <td>{ele.email}</td>
              <td><UpdateUser ele={ele} e={e} loginDetail={loginDetail} users={users}/></td>
              <td><DeleteUser ele={ele} e={e} loginDetail={loginDetail} users={users}/></td>
            </tr>

          )
        })
       }
            </tbody>
          </table>:null
}
   </CardContent>
 </Collapse>
</Card>
    </div>
  )
}
