import React, { useState } from 'react'
import axios from 'axios'
import './AddAUser.css'
import { serverUrl } from '../../AppUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UpdateUser.css'

export default function UpdateUser({e,loginDetail,users,ele}) {
    console.log(e,"e",loginDetail,"loginDetail")
    let [updateUserPop,setUpdateUserPop] =useState(false);
    const notify =(text)=> toast(text)
    
    const handleSubmit =(event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      var jan312009 = new Date();
var eightMonthsFromJan312009  = jan312009.setMonth(jan312009.getMonth()+1);
      const userDetail = {
          role:data.get('role'), 
      }
      console.log(userDetail,"user");
      const addAuser = (userDetail) =>{
        axios.post(`${serverUrl.u}/api/usr/updateUser`,{
            houseId:e.houseId,
            roomIds:e.houseId,
            adminId:loginDetail.userName,
            userId:ele.email,  
            role:Number(userDetail.role), 
            validtill:eightMonthsFromJan312009
        }).then(function (res) {
             console.log(res);
             if(res.status===200){
              notify(res.data.msg)
              users(e)
              setUpdateUserPop(false)
             }
        }).catch(function (err) {
            console.log(err);
        })
    }
     addAuser(userDetail);
    }
  return (
    <>
    
    
      <button className='btn btn-primary' onClick={()=>setUpdateUserPop(true)}>Update User Role</button>
      
      {
        updateUserPop===true?
        <div className='addUserContainer'>
            <span className='cross' onClick={()=>setUpdateUserPop(false)}>X</span>
           <form validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
          <div className='SignIN'>Update User Role</div>
          <div className="form-group">
             <label className='login-label' >User ID</label>
             <input type="text" className="form-control"  value={ele.email}/>
          </div>
          <label className='login-label' >Role</label>
          <div className="form-group">
          
            <select className="form-control" type="radio" name="role" defaultValue={1} autoComplete="role" id="exampleRadios1" >
               <option value={1}>Admin</option>
               <option value={2}>User</option>
               <option value={3}>Gest</option>
             </select>   
            
          </div>
         

          
          <button type='submit' className='btn btn-primary'>Update User</button>
          </form> 
        </div>:null
      }
      <ToastContainer/>
    </>
  )
}
