import { userDetailREducer,roomDetailReducer } from './Reducer'
import {createStore,combineReducers} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

const persistConfig = {
    key: 'persist-key',
    storage
}

const persistedReducer = persistReducer(persistConfig,combineReducers({
    loginDetail: userDetailREducer,
    roomDetail:roomDetailReducer,
   
}))

let store = createStore(persistedReducer);
let persistor = persistStore(store)
export default store;
export {persistor}