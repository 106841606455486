
export const userDetailREducer = (state={},action)=>{
    switch(action.type){
        case "userDetail":
                return action.payload;
        case "removeUserDetail": 
                return action.payload;
        default :
              return state;        
    }
}

export const roomDetailReducer = (state =[],action)=>{
        switch(action.type){
                case "roomDetail":
                        return action.payload;
                default :
                      return state;        
            }  
}