import React, { useState } from 'react'
import axios from 'axios'
import './AddAUser.css'
import { serverUrl } from '../../AppUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function AddAUser({e,loginDetail,users}) {
    console.log(e,"e",loginDetail,"loginDetail")
    let [addUserPop,setAddUserPop] =useState(false);
    const notify =(text)=> toast(text)
    
    const handleSubmit =(event)=>{
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      const userDetail = {
          userName:data.get('user_name'),
          email:data.get('email'),
          access:data.get('access')
      }
      console.log(userDetail,"user");
      const addAuser = (userDetail) =>{
        axios.post(`${serverUrl.u}/api/usr/addUser`,{
            houseId:e.houseId,
            propertyId:loginDetail.property,
            roomsName:e.roomsName,
            name:userDetail.userName,
            email:userDetail.email,
            isMaster:userDetail.access=="true"?true:false,
            timed:new Date(),
            owner:false,
            adminId:loginDetail.userName,
        }).then(function (res) {
             console.log(res);
             if(res.status===200){
              notify(res.data.msg)
              users(e)
              setAddUserPop(false)
             }
        }).catch(function (err) {
            console.log(err);
            notify(err.response.data.message)
        })
    }
     addAuser(userDetail);
    }
  return (
    <>
    <ToastContainer/>
    <div className='adduser-btn'>
      <button className='btn btn-primary' onClick={()=>setAddUserPop(true)}>ADD User</button>
      </div>
      {
        addUserPop===true?
        <div className='addUserContainer'>
            <span className='cross' onClick={()=>setAddUserPop(false)}>X</span>
           <form validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
          <div className='SignIN'>Add New User</div>
          <div className="form-group">
             <label className='login-label' >User Name</label>
             <input type="text" className="form-control" required  name="user_name" autoComplete="user_name" placeholder="username"/>
          </div>
          <div className="form-group">
             <label className='login-label' >Phone No.</label>
             <input type="text" className="form-control" required  name="email" autoComplete="current-email" placeholder="Phone Number"/>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="access" autoComplete="current-access" id="exampleRadios1" value={false} checked/>
            <label class="form-check-label" for="exampleRadios1">
             User
            </label>
          </div>
          <div class="form-check">
             <input class="form-check-input" type="radio" name="access" autoComplete="current-access" id="exampleRadios2" value={true}/>
             <label class="form-check-label" for="exampleRadios2">
                Admin
             </label>
          </div>

          
          <button type='submit' className='btn btn-primary'>ADD User</button>
          </form> 
        </div>:null
      }
    </>
  )
}
