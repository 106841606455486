import React from 'react'
import './Room.css'

import RoomExpend from './RoomExpend';
import TopHeader from '../TopHeader/TopHeader';
import { useSelector } from 'react-redux';
export default function Room() {
  let roomData = useSelector((state)=>state.roomDetail)
  console.log(roomData);
  return (
    <div className='room-Container'>
      <TopHeader/>
      <div className='rooms'>
      {
        roomData.length!==0?
        roomData.map((e)=>{
          return <RoomExpend e={e}/>
        }):null
      }
      </div>
    </div>
  )
}
