import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { removeUserDetail } from '../Redux/Action';
import './Top_Header.css'
import { useNavigate } from 'react-router-dom';

export default function TopHeader() {
    let loginDetail = useSelector((state)=>state.loginDetail)
    let dispatch = useDispatch();
    let navigate = useNavigate();
     console.log(loginDetail,"login");
    return (
        <div className='top-header-container'>
        <div className='top-logo-container'>
            
               {/* <img className='menu-img' src={process.env.PUBLIC_URL+"Menu.png" } alt="menu" />
               <img className='logo-img' src={process.env.PUBLIC_URL+"Aliste Logo.png"} alt="company-logo"/> */}
               <div style={{marginLeft:"6%"}}>
               <span className='logo-name'>Housr</span>
               <br/>
                 <span className='logo-slaes'></span>
               </div>
            </div>
        <div className='top-side'>
            
            <div className="row" style={{paddingTop: "9px"}}><div className="col name">
                
                <div style={{flexDirection:"row", display:"flex", alignItems:"center"}}>
                {/* <img className='top-header-icon' src={process.env.PUBLIC_URL+`/puplic`} alt="logo"/> */}
                    <span style={{fontSize:"18px",fontWeight:"400"}}>login</span>
                </div>
               
            </div>
                <div className="col">
                    <div style={{float:"right", marginRight:"20px"}}>
                        <span style={{marginRight:"15px"}}>Welcome,<strong>{loginDetail.userName}</strong> </span>
                        <button type="button" className="btn btn-primary btn-sm" onClick={()=>{dispatch(removeUserDetail({}))
                          navigate('/')  }} >Logout</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
