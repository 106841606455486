import React, { useEffect, useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom';
import { userDetail,roomDetails } from '../Redux/Action';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../../AppUrl';
export default function Login() {
    const dispatch=useDispatch();
    const navigate = useNavigate()
    let [propertyIds,setPropertyIds]=useState([])
    useEffect(()=>{
       axios.post(`${serverUrl.u}/api/cmp/getProperty`,{
        companyCode:"34848e"
       }).then(function(res){
        console.log(res);
           console.log(res.data.propertyId);
           if(res.status===200){
            setPropertyIds(res.data.property)
           }
           

       }).catch(function (err) {
           console.log(err);
       })
    },[])
    const handleSubmit =(event)=>{
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const loginDetail = {
            userName:data.get('user_name'),
            password:data.get('password'),
            property:data.get('property')
        }
        console.log(loginDetail,"login");

        const getPropertyDetail =async()=>{
          await axios.post(`${serverUrl.u}/api/prp/getHouseIdAndRooms`,{
            propertyId:loginDetail.property
          }).then(function (res) {
            console.log(res,"room");
            dispatch(roomDetails(res.data.datas.roomss))
          }).catch(function (err) {
            console.log(err);
          })
        }
        getPropertyDetail();
        navigate('/home')
        dispatch(userDetail(loginDetail))
    }
  return (
    <div className='login'>
        <div className='Login-container'>
          {
            propertyIds.length!==0?
          <form validate={true.toString()} onSubmit={handleSubmit} id="create-course-form">
          <div className='SignIN'>Sign In</div>
          <div className="form-group">
             <label className='login-label' >User Name</label>
             <input type="text" className="form-control" required  name="user_name" autoComplete="user_name" placeholder="username"/>
          </div>
          <div className="form-group">
             <label className='login-label' >Password</label>
             <input type="password" className="form-control" name="password" autoComplete="current-password" placeholder="password"/>
          </div>
          <div className="form-group">
             <label className='login-label' >Properties</label>
             <select  className="form-control" name='property' autoComplete="property">
               {
                propertyIds.map((e)=>{
                  return <option value={e._id}>{e.propertyName}</option>
                })
               } 
             </select>
          </div>
          
          <button type='submit' className='btn btn-primary'>Sign In</button>
          </form>:<div>Loading....</div>
           }
        </div>

    </div>
  )
}
