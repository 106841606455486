import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Route,Routes} from 'react-router-dom';
import Login from './Component/Login/Login';
import Room from './Component/Rooms/Room';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
      <Routes>
         <Route path="/" element={<Login />}/>
          <Route index element={<Login />} />
          <Route path="/home" element={<Room/>}/>
          
          <Route path="*" element={<Login />} />
        
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
