export const userDetail = (data)=>{
    return {
        type:"userDetail",
        payload:data
    }
}

export const removeUserDetail = (data)=>{
    return {
        type:"removeUserDetail",
        payload:data
    }
}

export const roomDetails = (data)=>{
     return {
        type:"roomDetail",
        payload:data
     }
}
 