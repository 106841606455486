import axios from "axios"
import { serverUrl } from "../../AppUrl"


export const userList =async(data,loginDetail)=>{
    return await axios.post(`${serverUrl.u}/api/usr/getAllUser`,{
        houseId:data.houseId,
        propertyId:loginDetail.property
    }).then(function (res) {
        console.log(res);
        return res;
    }).catch(function (err) {
        console.log(err);
        return err;
    })
}